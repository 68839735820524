.user {
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #9d9d9d;

  h2 {
    display: flex;
    margin: 0;
    line-height: 40px;

    i {
      margin-left: auto;
      font-size: 20px;
      color: #666;
      cursor: pointer;
    }
  }

  &_addUser {
    display: flex;

    input {
      flex-grow: 1;
      height: 40px;
      margin-right: 10px;
      padding: 4px 10px;
      font-size: 16px;
    }

    button {
      height: 40px;
      width: 60px;
    }
  }
}
