:root {
  --header-height: 60px;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-top: var(--header-height);
  overscroll-behavior-y: contain;
  font-family: "problems", sans-serif;
}

body > section {
  height: calc(80vh - var(--header-height));
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 99px;
  color: #444;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: #333;
}

.pokeball {
  display: flex;
  flex-direction: column;
}

.pokeball svg {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}

.pokeball__body {
  transform: translateY(50px);
  z-index: 5;
}

.pokeball__body svg {
  animation: bounce 1s ease infinite;
  transform-origin: bottom;
}

.pokeball__shadow {
  flex-shrink: 0;
}

.pokeball__shadow svg {
  animation: squeeze 1s ease infinite;
  transform-origin: bottom;
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 38px;
  }
}

/* Step 2, text 'NO!' */
@keyframes wowBounce {
  0% {
    color: red;
  }

  20% {
    transform: scale(5);
    color: red;
  }

  100% {
    transform: scale(1);
    color: #444444;
  }
}

/* Step 3, text '.!.' */
@keyframes wowBounceRotate {
  0% {
    color: red;
  }

  20% {
    transform: scale(5);
    color: red;
  }

  100% {
    transform: scale(1) rotate(360deg);
    color: #444444;
  }
}

@keyframes flash {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes squeeze {
  0% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(0.9, 0.8);
  }
  30% {
    transform: scale(0.8, 0.9);
  }
  50% {
    transform: scale(0.95, 0.9);
  }
  57% {
    transform: scale(0.9);
  }
  64% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(0.9);
  }
}
