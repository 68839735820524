.translate {
  display: flex;

  &_item {
    flex-grow: 1;
  }

  label {
    display: block;
  }

  textarea {
    width: 100%;
  }
}