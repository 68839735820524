.header {
  position: fixed;
  top: calc(-100% + var(--header-height));
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fbfbfb;
  box-shadow: 0 1px 4px 2px #f1f1f1;
  z-index: 50;

  &_secret {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 20px;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.35s ease-out, opacity 0.35s ease-out;

    &:global(.header__secret--visible) {
      visibility: visible;
      opacity: 1;
    }
  }

  &_container {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
    padding: 0 10px;
  }

  &_logo {
    flex-shrink: 0;
    margin-right: 40px;
  }

  & nav a {
    font-size: 24px;
    font-weight: 900;
  }

  &_drag {
    font-size: 12px;
    padding-left: 40px;
    cursor: grab;
    user-select: none;
  }

  &_drag:active,
  &_drag:focus {
    cursor: grabbing;
  }

  &_dragAngle {
    display: block;
    animation: flash 0.4s ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  &_dragAngle:nth-child(2) {
    animation-delay: 0.2s;
  }

  &_dragAngle:nth-child(3) {
    animation-delay: 0.4s;
  }

  &__stick .header_drag {
    transform: scale(1, -1);
  }

  &_menu {
    flex-grow: 1;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media screen and (max-width: 480px) {
    &_drag {
      padding-left: 20px;
    }
  }
}
