.funBlock {
  /*position: absolute;*/
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
  /*visibility: hidden;*/
  /*opacity: 0;*/
  transition: opacity 0.15s ease-out;
  overflow: hidden;
  z-index: 10;

  &_title {
    padding: 20px;
    cursor: pointer;
    user-select: none;
  }

  &:target {
    visibility: visible;
    opacity: 1;
  }

  :global {
    .fun-block__title--step-2 {
      animation: wowBounce 0.7s ease-out;
    }

    .fun-block__title--step-3 {
      animation: wowBounceRotate 1.7s ease-out forwards;
    }

    .fun-block__title--step-3-active {
      color: red;
    }

    .fun-block__title--step-2,
    .fun-block__title--step-3 {
      pointer-events: none;
    }
  }

  @media screen and (pointer: fine) {
    &_title:hover {
      color: red;
    }
  }
}
