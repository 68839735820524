.order {
  &_addOrder {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    input {
      height: 60px;
      padding: 4px 12px;
      font-size: 18px;
    }
  }

  &_addOrderTitle {
    flex-grow: 1;
    margin-right: 10px;
  }

  &_addOrderPrice {
    width: 60px;
    flex-shrink: 0;
    text-align: center;

    // remove arrows
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
      appearance: none;
    }
  }

  &_addOrderSubmit {
    width: 100%;
    height: 60px;
    margin-top: 10px;
    padding: 4px 12px;
    font-size: 40px;
    border: 1px solid #444;
  }

  &_orderList {
    display: flex;
    flex-direction: column;
  }

  &_orderListItem {
    height: auto;
    color: #484848;
    margin-bottom: 30px;
    padding: 20px 10px;
    // you know
    border: 1px solid #3a3a3a;
    box-shadow: 2px 2px 2px 0px #9d9d9d;
    background-color: #fff;
    border-radius: 3px;

    &__first {
      order: -1;
    }
  }

  &_orderItem {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 14px;
      border-bottom: 1px dashed #555;
      padding-bottom: 14px;
    }
  }

  &_orderItemTitle {
    flex-grow: 1;
    margin-top: 12px;
    margin-right: 12px;
    font-size: 22px;
    font-weight: 500;
  }

  &_orderItemPrice,
  &_orderItemCount {
    flex-shrink: 0;
    margin-top: 12px;
    margin-right: 8px;
    font-size: 22px;
    font-weight: 700;
  }

  &_orderItemCountForm {
    display: flex;
  }

  &_orderItemCountIncrease,
  &_orderItemCountDecrease {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin: 0 2px;
    font-size: 16px;
    font-weight: 900;
  }

  &_total {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    font-size: 26px;

    h3 {
      margin-right: 6px;
    }
  }
}
